import React, { useContext } from "react";
import "./Works.css";
import word from "../../img/word.png";
import download from "../../img/download.jpg"
import download1 from "../../img/html-logo.png"
import download2 from "../../img/download2.jpg"
import javascript from "../../img/javascript.png";
import css from "../../img/css-logo.webp";
import boot from "../../img/bootstrap.jpg";
import next from "../../img/next-logo.png";
import tal from "../../img/tal-logo.png";
import woo from "../../img/woo-logo.png"
import ele from "../../img/png-clipart-elementor-logo-thumbnail-tech-companies.png";
import vs from "../../img/vscode.png";
import wp from "../../img/page-logo.jpg";
import shop from "../../img/sho.png"
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import {Link} from 'react-scroll'
const Works = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  return (
    <>
    <div className="works" id="works">
      {/* left side */}
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={download} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={download1} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={javascript} alt="" />
          </div>{" "}
          <div className="w-secCircle">
            <img src={download2} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={next} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={boot} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={css} alt="" />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle-l blueCircle"></div>
        <div className="w-backCircle-l yellowCircle"></div>
      </div>   
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <img src={tal} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={woo} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={ele} alt="" />
          </div>{" "}
          <div className="w-secCircle">
            <img src={vs} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={wp} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={shop} alt="" />
          </div>
          <div className="w-secCircle">
            <img src={word} alt="" />
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle blueCircle"></div>
        <div className="w-backCircle yellowCircle"></div>
      </div>    
      </div>
    </>
  );
};

export default Works;
