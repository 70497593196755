import React, { useContext } from "react";
import "./Services.css";
import { themeContext } from "../../Context";
import Shoaib from './Shoaib Ali.pdf';
import rocket from '../../img/rocket.png';
import strong from '../../img/strong.png';
import respon from '../../img/respon.png';
import newpic from '../../img/newpic.png';
import Email from "@iconscout/react-unicons/icons/uil-envelope";
import Whatsapp from "@iconscout/react-unicons/icons/uil-whatsapp"

const Services = () => {
  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;


  return (
    <div className="services" id="services">
      {/* left side */}
      <div className="awesome">
        <div className="about">
          <img src={newpic} alt="pic" className="newpic" />
          <div className="container">
            <span style={{ color: darkMode ? "white" : "" }} className="about-me" >About Me</span>
            <p style={{ color: darkMode ? "white" : "" }} >Here’s Shoaib Ali, a dedicated and passionate WordPress, Shopify, and front-end developer with over 4 years of hands on experience creating dynamic, user-centric web solutions. My journey in web development started with a fascination for the internet's inner workings  driving me to master building websites that are not only functional but also visually appealing and optimized for performance.</p>
            <p style={{ color: 'blue', fontWeight: 'bold' }}> Contact me to discuss your next project! </p>
            <div className="iconss" style={{ display: "flex", columnGap: "20px" }} >
              <p>
                <a href={Shoaib} download>
                  <button className="button v-button">Download CV</button>
                </a>
              </p>
              <p>
                <a href="mailto:aliwebguru404@gmail.com" target="blank" >
                  <button className="button v-button">Email</button>
                </a>
              </p>
              <p>
                <a href="https://wa.me/+923046413094" target="blank" >
                  <button className="button v-button">WhatsApp</button>
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* dark mode */}

        <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
      </div>
      <div className="fac">
        <div className="nnn">
          <img src={rocket} alt="" style={{ height: 100, width: 100, paddingTop: 40 }} />
          <div style={{ color: darkMode ? "white" : "" }}>Fast Working</div>
        </div>
        <div className="nnn">
          <img src={strong} alt="" style={{ height: 150, width: 150 }} />
          <div style={{ color: darkMode ? "white" : "" }}>Easy To Adapt</div>
        </div>
        <div className="nnn">
          <img src={respon} alt="" style={{ height: 150, width: 200 }} />
          <div style={{ color: darkMode ? "white" : "" }}>Responsive</div>
        </div>
      </div>
    </div>
  );
};

export default Services;
