import React, { useContext } from "react";
import "./Portfolio.css";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css";
import affinity from "../../img/affnty.png";
import fiber from "../../img/fiber.png";
import visa from "../../img/arueal.png";
import WhatsApp from "../../img/inguinii.png";
import cuero from "../../img/cuero.png"
import limit from "../../img/limt.png"
import siddiq from "../../img/saddiq.png"
import speed from "../../img/speed.png"
import skill from "../../img/skill.png"
import ss from "../../img/ss.png"
import screen from "../../img/an.png"
import Scr from "../../img/exotic.png";
import { themeContext } from "../../Context";
import why from "../../img/why.png"
import { Link } from "react-scroll";
import Reviews from "../review/Reviews";
import { motion } from "framer-motion";
const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <>
      <div className="portfolio" id="portfolio">
        {/* heading */}
        <span style={{ color: darkMode ? 'white' : '' }}>Recent Projects</span>
        <span>Portfolio</span>

        {/* slider */}
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          grabCursor={true}
          className="portfolio-slider"
        >
          <SwiperSlide>
            <img src={affinity} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={fiber} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={screen} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={Scr} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={visa} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={WhatsApp} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={cuero} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={limit} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={siddiq} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={speed} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ss} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={skill} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="why">
        <div className="why-text">
          <span style={{ color: darkMode ? "white" : "" }} className="about-me" >Why Choose Me?</span>
          <p>Choosing me to build your website opens a wide range of opportunities for business growth. We don't just create a website to transition physical processes into digital mediums. Our focus remains on providing an experience that gives a competitive edge to our clients, elevating their business in the industry.<br />
            <br />

            Therefore, you can rely on me as we turn your idea into a unique website product and take it to the next level. All you have to do is consult with our experts and start your journey for business digitization.<br />
            <br />

            We listen to our clients' requirements and add further value to build something unique that sets the trend in the market. That’s why we ensure maximum ROI after project completion while streamlining the overall business process.</p>
          <Link to="contact" smooth={true} spy={true}>
            <button className="button i-button">Hire me</button>
          </Link>
        </div>
        <div className="why-img">
          <motion.img
            src={why}
            alt="Vector"
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5 }}
            viewport={{ once: true }}
          />
        </div>
      </div>
      <div className="review">
        <span style={{ color: darkMode ? "white" : "" }} className="about-me" >Customer Reviews</span>
        <Reviews />
      </div>
    </>
  );
};

export default Portfolio;
