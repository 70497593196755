import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import Services from "./components/Services/Services";
import "./App.css";
import Experience from "./components/Experience/Experience";
import Works from "./components/Works/Works";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import { useContext } from "react";
import { themeContext } from "./Context";
import Chang from "./components/Chang/Chang";
// import SplashScreen from "./components/SplashScreen";
function App() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  // const [showSplash, setShowSplash] = useState(true);
  // useEffect(() => {
  //    const timer = setTimeout(() => {
  //      setShowSplash(false);
  //    }, 7000);
 
  //    return () => clearTimeout(timer);
  //  }, []);

  return (
    // <>
    // {showSplash ? (
    //     <SplashScreen />
    //   ) : (
    
    <div
      className="App"
      style={{
        background: darkMode ? "black" : "",
        color: darkMode ? "white" : "",
      }}
    >
      <Navbar />
      <Intro />
      <Services />
      <Experience />
      <Works />
      <Chang />
      <Portfolio />
      <Contact />
      <Footer />
    </div>
    //   )}
    // </>
  );
}

export default App;
