import React, { useContext } from "react";
import sh from '../../img/sho.png';
import wss from '../../img/word.png';
import react from '../../img/react.png';
import "./Card.css";
import { themeContext } from "../../Context";

const Card = ({ emoji, heading, detail, color }) => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    // <div className="card" style={{borderColor: {color}}}> 
    //   <img src={emoji} alt="" />
    //   <span>{heading}</span>
    //   <span>{detail}</span>
    // </div>
    <>
      <div className="card-e-top">
        <span style={{ color: darkMode ? "white" : "" }} className="about-me" >Experience</span>
      </div>
      <div className="container-card-e-m">
        <div className="container-card-e" style={{ borderColor: { color } }}>
          <div className="card">
            <div className="image">
              <img href="#" src={react} />
            </div>
            <div className="content-top">
              <h1>Front-End</h1>
              <p>Read More</p>
            </div>
            <div className="content">
              <h3>Front-End Development</h3>
              <p>As a front-end developer, I craft responsive, interactive user interfaces that engage visitors and provide a smooth browsing experience. My expertise in HTML, CSS, and JavaScript is complemented by my proficiency with modern frameworks and libraries.</p>
            </div>
          </div>
        </div>
        <div className="container-card-e" style={{ borderColor: { color } }}>
          <div className="card">
            <div className="image">
              <img href="#" src={wss} />
            </div>
            <div className="content-top">
              <h1>WordPress</h1>
              <p>Read More</p>
            </div>
            <div className="content">
              <h3>WordPress Development</h3>
              <p>Custom theme and plugin development, site maintenance, optimization. I'm experienced with various plugins, including the Fawry payment plugin and Link Whisper for internal linking.</p>
            </div>
          </div>
        </div>
        <div className="container-card-e" style={{ borderColor: { color } }}>
          <div className="card">
            <div className="image">
              <img href="#" src={sh} />
            </div>
            <div className="content-top">
              <h1>Shopify</h1>
              <p>Read More</p>
            </div>
            <div className="content">
              <h3>Shopify Development</h3>
              <p>Store setup, theme customization, app integration, e-commerce optimization.</p>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Card;
