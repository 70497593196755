import React, { useContext } from "react";
import "./chang.css"
import "../Services/Services.css"
import Card from "../Card/Card";
import { motion } from "framer-motion";
import heartemoji from "../../img/heartemoji.png"
import glasses from "../../img/glasses.png"
import humble from "../../img/humble.png"
import { themeContext } from "../../Context";
import Website from "@iconscout/react-unicons/icons/uil-desktop"
import Shop from "@iconscout/react-unicons/icons/uil-shop"
import Re from "@iconscout/react-unicons/icons/uil-repeat"

const Chang = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  // transition
  const transition = {
    duration: 1,
    type: "spring",
  };

  return (
    <>

      <div className="cards">
        {/* first card */}
        <motion.div
          initial={{}}
          whileInView={{ left: "-4rem" }}
          transition={transition}
        >
          <Card
            emoji={heartemoji}
            heading={"Front-End Website"}
            detail={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti architecto assumenda facere omnis voluptatem non veniam voluptates atque? Iure expedita natus nihil totam ab, sint similique illum quos fuga ipsa! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti architecto assumenda facere omnis voluptatem non veniam voluptates atque? Iure expedita natus nihil totam ab, sint similique illum quos fuga ipsa!"}
          />
        </motion.div>
        {/* second card */}
        {/* <motion.div
        initial={{  }}
        whileInView={{ left: "-15rem" }}
        transition={transition}
      >
        <Card
          emoji={glasses}
          heading={"WordPress Website"}
          detail={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti architecto assumenda facere omnis voluptatem non veniam voluptates atque? Iure expedita natus nihil totam ab, sint similique illum quos fuga ipsa! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti architecto assumenda facere omnis voluptatem non veniam voluptates atque? Iure expedita natus nihil totam ab, sint similique illum quos fuga ipsa!"}
        />
      </motion.div> */}
        {/* 3rd */}
        {/* <motion.div
        initial={{  }}
        whileInView={{  }}
        transition={transition}
      >
        <Card
          emoji={humble}
          heading={"Shopify Website"}
          detail={
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti architecto assumenda facere omnis voluptatem non veniam voluptates atque? Iure expedita natus nihil totam ab, sint similique illum quos fuga ipsa! Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti architecto assumenda facere omnis voluptatem non veniam voluptates atque? Iure expedita natus nihil totam ab, sint similique illum quos fuga ipsa!"
          }
          color="rgba(252, 166, 31, 0.45)"
        />
      </motion.div> */}
        {/* <div
        className="blur s-blur2"
        style={{ background: "var(--purple)" }}
      ></div> */}
      </div>
      <div className="motion-bottom">
        <p>In the past four years, I have successfully completed numerous projects  from developing custom WordPress sites to launching high converting Shopify stores. My approach is client-focused, ensuring I understand their goals and deliver solutions that exceed expectations. I take pride in my ability to solve complex problems and stay updated with the latest industry trends. Whether it's setting up plantillas gratuitas  para WordPress de refugios  de animales or working with Link Whisper WordPress plugin for effective internal linking, I am committed to delivering excellence.</p>
      </div>
      <div className="need">
        <div className="need-text">
          <span style={{ color: darkMode ? "white" : "" }} className="about-me" >What specific web solution are you seeking?</span>
          <p>Web development services encompass a wide range of solutions tailored to create various web-based software, guaranteeing exceptional user experiences. While different types of web solutions may appear similar at first glance, our approach is nuanced, recognizing the unique factors that contribute to success in each scenario.</p>
          <div className="need-card-t">
          <Re color="var(--orange)" size="2rem" />
            <h1>Redesign Website</h1>
            <p>Our website redesign services breathe new life into your online presence, revitalizing outdated designs and enhancing user engagement. With a focus on modern aesthetics and intuitive navigation, we ensure your website reflects your brand's evolution while maximizing its impact and functionality.</p>
          </div>
        </div>
        <div className="need-card">
          <div className="need-card-one">
            <Website color="var(--orange)" size="2rem" />
            <h1>Website</h1>
            <p>Our crafted websites have been utilized by over 50 businesses, governmental bodies, and non-profit organizations for corporate presentations and brand enhancement. Ensuring user-friendly experiences, we incorporate easy-to-use page editors for seamless dynamic content management.</p>
          </div>
          <div className="need-card-one">
          <Shop color="var(--orange)" size="2rem" />
            <h1>Ecommerce</h1>
            <p>With 6 years of experience in ecommerce development, we have cultivated our expertise, ranging from entry-level shops for startups to tailor-made ecommerce solutions designed for large-scale and high-growth enterprises. Our approach focuses on multiplying business efficiency through the utilization of scalable microservices architectures, enabling high levels of automation across all business processes.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Chang