import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './reviews.css';
import Faq from '../faq/Faq';

const reviewsData = [
    { id: 1, name: 'Affinity Marketing', review: "Shoaib did an amazing job developing my new affinity marketing website. His designs are visually stunning and the functionality is top-notch - I'm extremely satisfied with the finished website", image: require('../../img/affinity-logo.png'), rating: 5 },
    { id: 2, name: 'An-Nahl', review: "Shoaib's work on our new An-Nahl website was truly impressive. He captured our vision perfectly with a modern, user-friendly design and robust features", image: require('../../img/an-logo.avif'), rating: 5 },
    { id: 3, name: 'Arula Deals', review: "Shoaib did an exceptional job developing the new Arula Deals website. His clean designs and seamless functionality have given our e-commerce platform a major competitive edge.", image: require('../../img/arula-logo.png'), rating: 5 },
    { id: 4, name: 'Cuero Real', review: "Shoaib's work on our new Cuero Real website was outstanding. He delivered a visually stunning and highly functional site that perfectly showcases our leather goods products.", image: require('../../img/cuero-logo.webp'), rating: 5 },
    { id: 5, name: 'Exotic Drive DXB', review: "We're blown away by the website Shoaib created for Exotic Drive DXB. His designs perfectly captured the luxury automotive experience, and the site's features make booking exotic car rentals a breeze.", image: require('../../img/exotic-logo.png'), rating: 5 },
    // Add more reviews as needed
    { id: 6, name: 'Fiber Tex Int', review: "Shoaib did an excellent job developing our new Fiber Tex website. The modern design and intuitive navigation really showcase our fiber products effectively.", image: require('../../img/fiber-logo.png'), rating: 5 },
    { id: 7, name: 'Inguinii', review: "Shoaib's work on our Inguinii website was truly impressive. He delivered a visually stunning and user-friendly platform that aligns perfectly with our brand identity.", image: require('../../img/inguinii-logo.webp'), rating: 5 },
    { id: 8, name: 'Limitlessmanpower', review: "The design and functionality of the Limitlessmanpower website are exceptional, greatly enhancing user experience and accessibility. Your expertise in creating a seamless and visually appealing interface is truly commendable.", image: require('../../img/limit-logo.png'), rating: 5 },
    { id: 9, name: 'Siddiq Hospital', review: "The new Siddiq Hospital website you developed is outstanding. Your clean designs and seamless integration of online appointment booking have greatly improved our digital presence.", image: require('../../img/siddiq-logo.png'), rating: 5 },
    { id: 10, name: 'Skill Pro Studio', review: "The new website for Skill Pro Studio is simply amazing. The modern design and dynamic features really showcase our educational services in an engaging way.", image: require('../../img/skill-logo.png'), rating: 5 },
    { id: 11, name: 'Speed Shop', review: "We're thrilled with the new Speed Shop website you created. The design perfectly captures the high-performance automotive theme, while the e-commerce functionality makes it easy for customers to purchase parts and accessories.", image: require('../../img/speed-logo.png'), rating: 5 },
    { id: 12, name: 'SS Designings', review: "The new website for SS Designings is truly impressive. You captured our brand identity flawlessly with a sleek, modern design and robust portfolio showcase.", image: require('../../img/ss-logo.jpg'), rating: 5 },
];

const Reviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: true, // Show navigation arrows
        prevArrow: <button className="slick-prev" >&#10094;</button>, // Custom previous arrow
        nextArrow: <button className="slick-next" >&#10095;</button>, // Custom next arrow
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false, // Hide arrows on small screens
                }
            },
            {
                breakpoint: 468,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false, // Hide arrows on small screens
                }
            }

        ]
    };

    return (
        <>
        <div className="reviews-container">
            <Slider {...settings}>
                {reviewsData.map(review => (
                    <div key={review.id} className="review">
                        <img src={review.image} alt={review.name} className="review-image" />
                        <p className="review-text">{review.review}</p>
                        <div className="rating">
                            {[...Array(review.rating)].map((_, index) => (
                                <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
                            ))}
                        </div>
                        <h3 className="review-name">{review.name}</h3>
                    </div>
                ))}
            </Slider>
        </div>
        <Faq />
        </>
    );
};

export default Reviews;
