import React, { useContext } from 'react';
import FAQAccordion from './FAQAccordion';
import { themeContext } from "../../Context";

const faqs = [
    {
        question: 'What do you need to get started?',
        answer: 'I need your hosting credentials to install and setup WordPress on your domain as well as your logo, images and text to be used on the site.',
    },
    {
        question: 'Will my website be mobile friendly?',
        answer: 'Yes, absolutely! Your website will be FULLY Responsive across all devices – Tablet, Mobile, Desktop Devises and Browsers.',
    },
    {
        question: 'Will my website be SEO Friendly?',
        answer: 'Yes, your website will be built such that it will be SEO friendly.',
    },
    {
        question: 'Do you provide Domain & hosting?',
        answer: 'Yes, I do, I have set up my own hosting services optimized for the best performance and security for my clients.',
    },
    {
        question: 'Can you setup a Shopify account for me ?',
        answer: 'Yes I can setup a Shopify account for you with your choice of plan.',
    },
    {
        question: 'Can you customize our existing Shopify theme ?',
        answer: 'Yes I can customize your existing theme.',
    },
    // Add more FAQs as needed
];

const Faq = () => {
    const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
    return (
        <div style={{ width: '70vw', margin: "auto", marginTop: "100px" }}>
            <span style={{ color: darkMode ? "white" : "" }} className="about-me" >FAQ</span>
            <FAQAccordion faqs={faqs} />
        </div>
    );
};

export default Faq;
